import "native-promise-only";
import type {} from "services-comun/modules/types";

export interface IInitComponent {
    init: ()=>Promise<void>;
}

class Init {
    private onload: IInitComponent[];
    private viewport: IInitComponent[];
    private inited: IInitComponent[];
    private ready: boolean;

    public constructor() {
        this.onload = [];
        this.viewport = [];
        this.inited = [];
        this.ready = false;

        window.onload = ()=>{
            for (let i=0, cantidad=this.onload.length;i<cantidad;i++) {
                setTimeout(()=>{
                    this.onload[i].init()
                        .then(async ()=>{})
                        .catch(async ()=>{});
                }, 0);
            }
            setTimeout(()=>{
                this.onscrollInit();
            }, 0);
            this.ready = true;
        };

        document.addEventListener("scroll", ()=>{
            this.onscrollInit();
        });
    }

    public push(componente: IInitComponent):void {
        if (this.inited.indexOf(componente)<0) {
            setTimeout(()=>{
                componente.init()
                    .then(async ()=>{
                        this.inited.push(componente);
                    })
                    .catch(async ()=>{
                        if (!this.ready) {
                            setTimeout(()=>{
                                this.push(componente);
                            }, 100);
                        }
                    });
            }, 0);
        }
    }

    public pushDelayed(componente: IInitComponent):void {
        if (this.onload.indexOf(componente)<0) {
            if (this.ready) {
                setTimeout(() => {
                    componente.init().then(async ()=>{}).catch(async ()=>{});
                }, 0);
            } else {
                this.onload.push(componente);
            }
        }
    }

    // public pushOnViewPort(componente: IInitComponent):void {
    //     if (this.viewport.indexOf(componente)<0) {
    //         this.viewport.push(componente);
    //     }
    // }

    private onscrollInit(): void {
        const remaining: IInitComponent[] = [];
        const promesas: Promise<void>[] = [];

        for (let i=0, cantidad=this.viewport.length;i<cantidad;i++) {
            promesas.push(this.viewport[i].init()
                .then(async ()=>{

                }).catch(async ()=>{
                    remaining.push(this.viewport[i]);
                }));
        }
        Promise.all(promesas)
            .then(async ()=>{
                this.viewport = remaining;
                if (remaining.length==0) {
                    document.removeEventListener("scroll", ()=>{
                        this.onscrollInit();
                    });
                }
            })
            .catch(async ()=>{

            });
    }
}

export default new Init();
