import axios from "axios";
import {IRespuesta} from "services-comun/modules/net/interface";
import {Index} from "../index";

interface IFormLogin {
    email:string,
    password:string
}

const emailRegex= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Login extends Index {

    /*STATIC*/
    private static accessGranted(user:string, token:string): void {
        sessionStorage.setItem("username", user);
        sessionStorage.setItem("token", token);
        window.location.assign('graphs/');
    }

    /*INSTANCE*/
    protected readonly b1: HTMLLIElement;
    protected readonly b2: HTMLLIElement;
    protected readonly b3: HTMLLIElement;

    protected readonly bb1: HTMLElement;
    protected readonly bb2: HTMLElement;
    protected readonly bb3: HTMLElement;

    protected readonly email: HTMLInputElement;
    protected readonly password: HTMLInputElement;
    protected readonly email_error_msg: HTMLElement;
    protected readonly password_error_msg: HTMLElement;

    public static async init(): Promise<void> {
        new this();
    }

    constructor() {
        super();
        this.b1 = document.getElementById("login-button-b1") as HTMLLIElement;
        this.b2 = document.getElementById("login-button-b2") as HTMLLIElement;
        this.b3 = document.getElementById("login-button-b3") as HTMLLIElement;

        this.bb1 = document.getElementById("bb1") as HTMLElement;
        this.bb2 = document.getElementById("bb2") as HTMLElement;
        this.bb3 = document.getElementById("bb3") as HTMLElement;

        this.email = document.getElementById('login_email') as HTMLInputElement;
        this.password = document.getElementById('login_pwd') as HTMLInputElement;
        this.email_error_msg = document.getElementById('login_email_error_msg') as HTMLElement;
        this.password_error_msg = document.getElementById('login_pwd_error_msg') as HTMLElement;

        Index.isUserLogged(sessionStorage["token"]).then((result) => {
                if (result) {
                    window.location.assign('graphs/');
                }
            });

        Index.ocultar();

        this.b1.onclick=this.b2.onclick=this.b3.onclick= () => {
            this.checkAccess()
                .catch(err=>{
                    console.log(err);
                });
        }
    }

    protected async checkAccess(): Promise<void> {
        const form_login: IFormLogin = this.getFormValues();
        if(this.checkFields(form_login.email, form_login.password)){
            const {status, data} = await axios.get<IRespuesta<any>>(`/web/backoffice/genlogin/`, {
                params: {
                    email: form_login.email,
                    password: form_login.password,
                },
            }).catch(async (err) => {
                if (axios.isAxiosError(err)) {
                    if (err.response != undefined) {
                        this.showButton2();
                        return Promise.reject(err.response.statusText);
                    }
                    if (err.request != undefined) {
                        return Promise.reject(err.request);
                    }
                    return Promise.reject(err.message);
                }
                return Promise.reject(err);
            });
            if (status != 200 || !data.ok) {
                return Promise.reject(data);
            }
            if (!data.data.match){
                this.showButton2();
            } else if(!data.data.active){
                this.showButton3()
            } else {
                Login.accessGranted(form_login.email, data.data.token)
            }
        }
    }

    private getFormValues(): IFormLogin {
        return {
            email: this.email?.value??"",
            password: this.password?.value??"",
        }

    }

    private checkFields(user:string, pwd:string): boolean {
        let check: boolean;
        if (user.length == 0) {
            this.email.className = 'texto texto-2 error';
            this.email_error_msg.innerText = "This field is required";
        } else if (!emailRegex.test(user)) {
            this.email.className = 'texto texto-2 error';
            this.email_error_msg.innerText = "Please introduce a valid username";
        } else {
            this.email.className = 'texto texto-2';
            this.email_error_msg.innerText = "";
        }
        if (pwd.length == 0) {
            this.password.className = 'texto texto-2 error';
            this.password_error_msg.innerText = "This field is required";
            check=false;
        } else {
            this.password.className = 'texto texto-2';
            this.password_error_msg.innerText = "";
            check=true;
        }
        return check;
    }

    private showButton2(): void {
        this.bb1.style.display = 'none'
        this.bb2.style.display = 'flex';
        this.bb3.style.display = 'none';
    }

    private showButton3(): void {
        this.bb1.style.display = 'none'
        this.bb2.style.display = 'none';
        this.bb3.style.display = 'flex';
    }
}

export {Login}
