import {Login} from "../controllers/sections/login";
import init from "./init";

class Login_pc {
    public static async init(): Promise<void> {
        init.pushDelayed(Login);
    }

}

init.push(Login_pc);
