import axios from "axios";

import {IRespuesta} from "services-comun/modules/net/interface";

abstract class Index {

    /*STATIC*/
//
    protected static async isUserLogged(idToken : string) : Promise<boolean> {

        const {status, data} = await axios.get<IRespuesta<any>>(`/web/backoffice/islogged/`, {
            headers : {
                'authorization' : idToken,
            },
        }).catch(async (err)=>{
            if (axios.isAxiosError(err)) {
                if (err.response != undefined) {
                    return Promise.reject(err.response.statusText);
                }
                if (err.request!=undefined) {
                    return Promise.reject(err.request);
                }
                return Promise.reject(err.message);
            }
            return Promise.reject(err);
        });

        if (status!=200 || !data.ok) {
            return Promise.reject(data);
        }
        return data.data.type == "valid";

    }


    protected static ocultar() {
        (<HTMLElement>document.getElementById('menu_hamb')).style.visibility='hidden';
    }


    /*INSTANCE*/

}

export {Index}
